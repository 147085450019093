import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Arrow2 } from 'components/atoms/arrow2';
import { JaHeading as JaHeadingBase } from 'components/atoms/jaHeading';
import { ImgMask } from 'components/molecules/imgMask';
import { Link } from 'gatsby';
import { TweenMax } from 'gsap';
import React, { useEffect, useRef } from 'react';
import colors from 'style/colors';
import fonts from 'style/fonts';
import { text } from 'style/mixin';
import sizes from 'style/sizes';
import { baseTransitionSecond, mq } from 'style/variables';
import { useIsSp } from 'util/useIsSp';

type ProjectCardProps = {
  to: string;
  imgpath: string | string[];
  client?: string;
  title?: string | any;
  work?: string | any;
  tags?: Array<string>;
  size?: 'small' | 'large';
  spSize?: 'small'; // かなりパターン出てきたので苦し紛れにpropsを生やす
  imgSpSize?: 'full'; // かなりパターン出てきたので苦し紛れにpropsを生やす
  delay?: number;
  isEn?: boolean;
};

const Container = styled(Link)<Pick<ProjectCardProps, 'delay'>>`
  display: block;
  position: relative;
  line-height: 1;
  &:focus {
    outline-color: #fff;
  }
  ${({ delay }) =>
    delay &&
    css`
      opacity: 0;
    `};
  ${mq.onlypc} {
    display: flex;
    flex-direction: column;
  }
`;

const ImageWrapper = styled.div<Pick<ProjectCardProps, 'size' | 'spSize' | 'imgSpSize'>>`
  position: relative;
  vertical-align: bottom;
  width: 100%;
  background-color: ${colors.black01};
  overflow: hidden;
  ${mq.onlysp} {
    margin-bottom: ${sizes.margin[16]};
    ${({ imgSpSize }) =>
      imgSpSize === 'full'
        ? css`
            margin-left: -32px;
            margin-right: -32px;
            width: calc(100% + 32px * 2);
          `
        : null}
  }

  & > div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    & > div {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &::before {
    content: '';
    display: block;
    padding-top: ${({ size }) => (size === 'small' ? `${(250 / 334) * 100}%` : `${(334 / 506) * 100}%`)};
    ${mq.onlysp} {
      padding-top: ${({ size }) => (size === 'small' ? `${(184 / 246) * 100}%` : '100%')};
      ${({ spSize }) =>
        spSize === 'small'
          ? css`
              padding-top: ${(184 / 246) * 100}%;
            `
          : null}
    }
  }
`;

const Texts = styled.div<Pick<ProjectCardProps, 'size'>>`
  font-family: ${fonts.jaGothic};
  position: relative;
  &:focus {
    outline: none;
  }
  ${mq.onlypc} {
    padding-top: 32px;
    flex: 1;
  }
  ${mq.onlysp} {
    padding-top: 0;
    padding-left: 0;
  }
  > span:first-of-type {
    ${mq.onlysp} {
      right: ${({ size }) => (size === 'small' ? '0' : '32px')};
    }
  }
`;

const Title = styled.div<Pick<ProjectCardProps, 'size' | 'client' | 'isEn'>>`
  position: relative;
  ${mq.onlypc} {
    flex-grow: 1;
    ${({ size, client }) =>
      size === 'small' &&
      !client &&
      css`
        margin-top: 35.09px;
      `};
    > h3 {
      font-size: ${({ size }) => (size === 'small' ? '24px' : '32px')};
    }
  }
  [data-lang='en'] & {
    font-family: ${fonts.enSans};
  }
`;

const JaHeading = styled(JaHeadingBase)<Pick<ProjectCardProps, 'isEn'>>`
  ${mq.onlysp} {
    ${({ isEn }) =>
      isEn &&
      css`
        line-height: 1.2em;
      `};
  }
`;

const Client = styled.div<Pick<ProjectCardProps, 'size'>>`
  position: relative;
  font-size: ${({ size }) => (size === 'small' ? '15px' : '18px')};
  ${mq.onlypc} {
    ${text(15, 1.45)};
    padding-bottom: 20px;
    font-size: ${({ size }) => (size === 'small' ? '15px' : '18px')};
  }
  ${mq.onlysp} {
    padding-bottom: ${({ size }) => (size === 'small' ? `12px` : `24px`)};
  }

  span {
    display: inline-block;
    align-items: flex-start;
    line-height: 1.48;
    margin-bottom: -0.2em;
    ${mq.onlysp} {
      letter-spacing: 0.06em;
    }
    &:first-of-type:not(:last-of-type) {
      &::after {
        content: '';
        display: inline-block;
        width: 1px;
        height: 0.8em;
        margin: 0 ${sizes.margin[17]};
        background-color: ${colors.gray02};
        ${mq.onlysp} {
          margin: 0 15px 0 13px;
        }
      }
    }
  }
  [data-lang='en'] & {
    font-family: ${fonts.enSans};
  }
`;

export const ProjectCard: React.FC<ProjectCardProps> = props => {
  const container = useRef<HTMLLinkElement>(null);
  const img = useRef<HTMLDivElement>(null);
  const isSP = useIsSp();

  useEffect(() => {
    if (props.delay && props.delay > 0) {
      const delay = props.delay * 0.1 + baseTransitionSecond;
      if (container.current) container.current.style.opacity = '0';
      TweenMax.to(container.current, baseTransitionSecond, {
        opacity: 1,
        delay: delay,
      });
    }
  }, []);

  return (
    <Container to={props.to} delay={props.delay} ref={container}>
      <ImageWrapper ref={img} spSize={props.spSize} imgSpSize={props.imgSpSize}>
        <div>
          <ImgMask filename={props.imgpath} alt={props.title || ''} isCover />
        </div>
        <Arrow2 />
      </ImageWrapper>

      <Texts size={isSP ? 'small' : props.size} className="m-projectCard-text">
        {(props.client || props.work) && (
          <Client size={isSP ? 'small' : props.size}>
            <span>{props.client || props.work || ''}</span>
            {/* {props.work && <span>{props.work}</span>} */}
          </Client>
        )}
        {props.title && (
          <Title size={isSP ? 'small' : props.size} client={props.client || props.work}>
            <JaHeading
              size={props.size === 'small' || isSP ? 'small' : 'middle'}
              dangerouslySetInnerHTML={{
                __html: `${props.title}`,
              }}
              isEn={props.isEn}
            />
          </Title>
        )}
      </Texts>
    </Container>
  );
};
